<template>
    <div>
        <h2>{{ $t('mouvement.carnet_adresse') }}</h2>

        <CustomTable
			v-if="lieuTypeVisibles.length"
			ref="adresses_table"
            :id_table="tableId"
            primaryKey="lieu_id"
			:transformer="['LieuTransformer', 'withType']"
			:base-filters="filters"
            :busy.sync="table_busy"
            :hide_if_empty="true"
			:externSlotColumns="['registry_enable']"
        >
			<template v-slot:custom-slot-cell(registry_enable)="{ data }">
				<div v-if="data.lieu_type.lieutype_id == 1" class="custom-control custom-checkbox">
					<b-form-checkbox 
						name="check-button" 
						switch 
						v-model="data.registry_enable" 
						@change="switchRegistryGen(data)"
					>
						<template v-if="data.registry_enable">
							{{ $t('lieu.registry_enable') }}
						</template>
						<template v-else>
							{{ $t('lieu.registry_disable') }}
						</template>
					</b-form-checkbox>
				</div>
			</template>
		</CustomTable>
    </div>
</template>

<script type="text/javascript">
    import { EventBus } from 'EventBus'
    import Lieux from "@/mixins/Lieux.js"
    import Navigation from '@/mixins/Navigation.js'
    import Shutter from '@/mixins/Shutter.js'
    import CustomTable from "GroomyRoot/components/Table/CustomTable"
    import ShutterMouvements from '@/mixins/shutters-manager/Mouvements.js'

	export default {
		name: "CarnetAdresse",
        mixins: [Lieux, Navigation, Shutter, ShutterMouvements],
        props: {
			residences_only: { type: Boolean, default: false },
			can_chose_location: { type: Boolean, default: true },
			can_manage_registry_gen: { type: Boolean, default: false }
		},
        components: {
            CustomTable
        },
		data () {
			return {
                horse_id: null,
                table_busy: true,
				lieuTypeVisibles: [],

                events_tab: {
                    'TableAction::AddLieu': this.addLieu,
                    'TableAction::EditLieu': this.editLieu,
                    'TableAction::DeleteLieux': this.rmLieux,
                    'TableAction::SelectLieu': this.chooseResidence,
                    'TableAction::ArchiveLieu': this.archiveLieux,
					'TableAction::SetDefaultLieu': this.setDefaultLieu
				},
			}
		},
		computed: {
			tableId() {
				return this.can_chose_location ? 'carnet_adresse' : 'carnet_adresse_ro'
			},
			filters() {
				return {
					is_visible: {
						column: 'lieu_type',
						operator: 'isInList',
						value: this.lieuTypeVisibles
					},
					lieu: {
						column: 'lieu_archive',
						operator: 'isEqualTo',
						value: 0
					}
				}
			}
		},
		created() {
            this.init_component()
		},
		methods: {
            async init_component() {
				this.lieuTypeVisibles = this.residences_only ? [1] : await this.getLieuTypeVisibles()
				this.selected_residence = this.$route.params.lieu_id
                this.horse_id = this.$route.params.horse_id

                this.update_list_residences()
            },

            chooseResidence(residence) {
				const temp = residence
				temp.lieu_type = residence.lieu_type.lieutype_id

				this.ok(temp)
				this.shutterPanel().close('carnet-adresse')
            },

            addLieu() {
                this.openAjoutLieu()
            },

            editLieu(lieu) {
                this.openEditLieu(lieu.lieu_id)
            },

            rmLieux(lieux) {
                const ids = this.getArrayObjProperty(lieux, 'lieu_id')
                this.deleteLieu(ids).then(() => {
                    this.successToast()
                    this.update_list_residences()
                })
                .catch(e => {
					console.log("err", e)
					if(e == "lieu_used") {
						this.failureToast("toast.error_del_lieu")
						return
					}

                    this.failureToast()
                })
            },

            archiveLieux(lieux) {
            	const ids = this.getArrayObjProperty(lieux, 'lieu_id')
                this.archiveLieuxByIds(ids).then(() => {
                    this.successToast()
                    this.update_list_residences()
                })
                .catch(e => {
					console.log("err", e)
					if(e == "lieu_used") {
						this.failureToast("toast.error_del_lieu")
						return
					}

                    this.failureToast()
                })
            },

            async update_list_residences() {
				if (this.$refs.adresses_table) {
					this.$refs.adresses_table.refreshTable()
				}
            },

            lieuFormSubmit() {
                this.update_list_residences()
            },

			switchRegistryGen(item) {
				this.changeLieuGenRegistryState(item.lieu_id, !item.registry_enable)
			},

			setDefaultLieu(lieu) {
				this.setLieuByDefault(lieu).then(() => {
					this.update_list_residences()
				})
			}
		}
	}
</script>
